import React, { useState, useRef } from "react"

const ImageSlider = ({tabIndex}) => {
  const items = [
    { id: 1, location: 'París', title: "París para dos", desc: "Descubra la magia de este destino con su persona favorita.", img: "paris-card-1-premio-mayor-escalera.png" },
    { id: 2, location: 'París', title: "Alojamiento", desc: "5 días y 4 noches llenos de historia y cultura.", img: "paris-card-2-premio-mayor-escalera.png" },
    { id: 3, location: 'París', title: "Experiencias únicas", desc: "La Torre Eiffel, el Museo del Louvre y el PSG lo esperan. ¡Viva lo mejor de la ciudad con sus Compras Maestras!", img: "paris-card-3-premio-mayor-escalera.png" },
    { id: 4, location: 'Patagonia', title: "Patagonia para dos", desc: "Elija a su compañero de viaje y descubran juntos el sur de Argentina.", img: "patagonia-card-1-premio-mayor-escalera.png" },
    { id: 5, location: 'Patagonia', title: "Alojamiento", desc: "5 días y 4 noches llenos de música y la mejor gastronomía.", img: "patagonia-card-2-premio-mayor-escalera.png" },
    { id: 6, location: 'Patagonia', title: "Experiencias únicas", desc: "Visitará los rincones más hermosos de la Patagonia, glaciares, lagos y montañas.", img: "patagonia-card-3-premio-mayor-escalera.png" },


    { id: 7, location: 'México', title: "México para dos", desc: "Viaje con su persona favorita y vivan juntos la carrera de sus vidas.", img: "mexico-card-1-premio-mayor-escalera.png" },
    { id: 8, location: 'México', title: "Alojamiento", desc: "Viva la emoción de la F1 durante 4 días y 3 noches. ¡Prepárese para la experiencia de su vida!", img: "mexico-card-2-premio-mayor-escalera.png" },
    { id: 9, location: 'México', title: "El Gran Premio de México", desc: "Disfrute la carrera, tómese una foto con el trofeo del campeonato, conozca su equipo favorito y mucho más.", img: "mexico-card-3-premio-mayor-escalera.png" },
    { id: 10, location: 'México', title: "Tour por la pista", desc: "Acompañe a los pilotos en una vuelta al circuito, donde compartirán conocimientos y curiosidades que solo los corredores conocen.", img: "mexico-card-4-premio-mayor-escalera.png" },
    
    
  ]
  const filteredItems = items.filter(itm => itm.location === tabIndex)
  const extendedItems = [filteredItems[filteredItems.length - 1], ...filteredItems, filteredItems[0]]
  const [currentIndex, setCurrentIndex] = useState(2)
  const [isTransitioning, setIsTransitioning] = useState(false);
  const startX = useRef(0)
  const isMouseDown = useRef(false)
  const carouselRef = useRef(null)
  const [dragDistance, setDragDistance] = useState(0)
  const sliderRef = useRef(null);

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(prevIndex => prevIndex + 1);
  }

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(prevIndex => prevIndex - 1);
  }
  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    console.log('currentIndex',currentIndex)
    if (currentIndex === 0) {
      setCurrentIndex(filteredItems.length -1);
    } else if (currentIndex === filteredItems.length + 1) {
      setCurrentIndex(2);
    }
  };

  const handleMouseDown = (e) => {
    isMouseDown.current = true
    startX.current = e.clientX
  }

  const handleMouseMove = (e) => {
    if (!isMouseDown.current) return
    const currentX = e.clientX
    setDragDistance(currentX - startX.current)
  }

  const handleMouseUp = () => {
    if (dragDistance > 100) {
      handlePrev()
    } else if (dragDistance < -100) {
      handleNext()
    }
    setDragDistance(0)
    isMouseDown.current = false
  }

  const handleTouchStart = (e) => {
    isMouseDown.current = true
    startX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    if (!isMouseDown.current) return
    const currentX = e.touches[0].clientX
    setDragDistance(currentX - startX.current)
  }

  const handleTouchEnd = () => {
    if (dragDistance > 100) {
      handlePrev()
    } else if (dragDistance < -100) {
      handleNext()
    }
    setDragDistance(0)
    isMouseDown.current = false
  }

  const handleClick = (index) => {
    setCurrentIndex(index)
    carouselRef.current.focus() // Enfocar el slider
  }

  return (

    <>
      <div
        className="image-slider"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={carouselRef}
        tabIndex={-1}
      >
        <div className="carousel" ref={sliderRef} 
        onTransitionEnd={handleTransitionEnd}
        >
          {extendedItems.filter(itm => itm.location === tabIndex).map((item, index) => {
            const offset = index - currentIndex
            const translateX = offset * 260
            const translateY = offset * 60
            const scale = 1 - Math.abs(offset) * 0.1
            const zIndex = items.length - Math.abs(offset)

            return (
              <div
                key={index}
                className="carousel-item"
                style={{
                  transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
                  zIndex,
                  opacity: Math.abs(offset) > 3 ? 0 : 1,
                }}
                onClick={() => handleClick(index)}
              >
                <div className="carousel-box">
                  <div className="contentImg">
                    <h4>{item.title}</h4>
                    <p className="desc">{item.desc}</p>
                  </div>
                  <img src={`/assets/mayor-award/${item.img}`} alt={item.title} />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="controls">
        <button className="nav-button prev" onClick={handlePrev}>
          <img src="/assets/mayor-award/left.png" alt="" />
        </button>
        <button className="nav-button next" onClick={handleNext}>
          <img src="/assets/mayor-award/right.png" alt="" />
        </button>
      </div>

    </>
  )
}

export default ImageSlider
