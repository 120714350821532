import React, {useState, useEffect} from 'react'

function ToastDavipuntos() {
  const [showToast, setShowToast] = useState(true)

  useEffect(() => {
    const isViewed = localStorage.getItem('toastViewed')
    if (isViewed) setShowToast(false)
  }, [])

  const closeToast = () => {
    localStorage.setItem('toastViewed', true)
    setShowToast(false);
  }
  const goToPoints = () => {
    const element = document.querySelector('.mc-davipuntos-page-progreso');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className={`mc-toast-davipuntos ${showToast ? 'show' : ''}`}>
      <img src="/assets/images/toast-davipuntos.png" onClick={goToPoints} />
      <div onClick={closeToast}></div>
    </div>
  )
}

export default ToastDavipuntos