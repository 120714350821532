import React from 'react'

import Columns, { Col } from '../../layouts/Columns/Columns'
import Button from '../atoms/Button'
import { sendDataLayer } from '../../utils/functions'

function Benefits({
  cta,
  link,
  event
}) {

  const handleBenefits = async () => {
    sendDataLayer({datalayer: {event: event ? event : 'steps',text: "Descubrir", link: link},repeat: true})
  }

  return (
    <div className='mc-page-content__benefits'>
      <h2>Explore más beneficios de su Tarjeta de Crédito Davivienda  Mastercard</h2>
      <Columns
        desktop={2}
        mobile={1}
        height={200}
      >
        <Col>
          <img src="/assets/images/mechanic_benefits.png" alt="Mujer tarjeta" />
        </Col>
        <Col>
          <h3>Explore más beneficios de su Tarjeta de Crédito Davivienda Mastercard</h3>
          <p>Viva al máximo todos los beneficios que tiene por estar siempre conectado con Davivienda.</p>

          {!event &&
            <div onClick={handleBenefits}>
              <Button
                type="primary"
                text={cta}
                loading={false}
                status="active"
                link={link}
              />
            </div>
          }

        </Col>
      </Columns>
    </div>
  )
}

export default Benefits