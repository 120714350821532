import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Icon from '../atoms/Icon';

const SliderPlanSection = ({tabIndex}) => {
  const sliderRef = useRef(null)
  const sectionRef = useRef(null)
  const [isFirstSlide, setIsFirstSlide] = useState(false)
  const [isLastSlide, setIsLastSlide] = useState(false)
  const [isSliderVisible, setIsSliderVisible] = useState(false)

  const CustomPrevArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-prev"><img src="/assets/images/flecha-down.png" alt="" /></button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-next"><img src="/assets/images/flecha-up.png" alt="" /></button>
  );
  const settings = {
    className: "center",
    // centerMode: true, 
    centerPadding: "0px", 
    slidesToShow: 2,  
    initialSlide: 0,  
    speed: 500,  
    focusOnSelect: true,
    infinite: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(next === sliderRef.current.innerSlider.state.slideCount - 2);
    },
    accessibility: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true, 
          centerPadding: "10px 50px", 
        }
      },

    ]

  };

  const list = [
    {
      id: 1,
      title: 'Dia 1',
      desc: 'Llegada a París',
      img: 'paris-slider-plan-1.png',
      location: 'París',
      benefits: [
        {
          icon: 'car',
          name: 'Transporte privado hasta el hotel.',
        },
        {
          icon: 'confetti',
          name: 'Bienvenida y recepción.',
        },
        {
          icon: 'restaurant',
          name: 'Cena especial.',
        },
      ]
    },
    {
      id: 2,
      title: 'Día 2',
      desc: 'Tour por la ciudad y cena',
      img: 'paris-slider-plan-2.png',
      location: 'París',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'map',
          name: 'Tour privado por la ciudad.',
        },
        {
          icon: 'wine',
          name: 'Cena en Madame Brasserie, el restaurante de la Torre Eiffel.',
        },
      ]
    },
    {
      id: 3,
      title: 'Día 3',
      desc: 'Visita al Louvre',
      img: 'paris-slider-plan-3.png',
      location: 'París',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'library',
          name: 'Visita guiada Museo Louvre.',
        },
        {
          icon: 'restaurant',
          name: 'Atardecer en Sacré-CœurCena y show en el icónico Moulin Rouge.',
        },
      ]
    },
    {
      id: 4,
      title: 'Día 4',
      desc: 'Partido del Paris Saint-Germain',
      img: 'paris-slider-plan-4.png',
      location: 'París',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'ball-football',
          name: 'Dos entradas para el partido PSG vs el equipo visitante.',
        },
      ]
    },
    {
      id: 5,
      title: 'Día 5',
      desc: 'Vuelo de regreso',
      img: 'paris-slider-plan-5.png',
      location: 'París',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'car',
          name: 'Transporte privado del hotel hasta el aeropuerto.',
        },
        {
          icon: 'airplane',
          name: 'Salida desde París.',
        },
      ]
    },
    {
      id: 6,
      title: 'Día 1',
      desc: 'Llegada a Buenos Aires',
      img: 'patagoniaslider-plan-1.png',
      location: 'Patagonia',
      benefits: [
        {
          icon: 'airplane',
          name: 'Vuelo hacia Bariloche.',
        },
        {
          icon: 'car',
          name: 'Transporte privado hasta el hotel.',
        },
        {
          icon: 'map',
          name: 'Visita al centro histórico y caminata por la ciudad.',
        },
      ]
    },
    {
      id: 7,
      title: 'Día 2',
      desc: 'Esquí en el Cerro Catedral',
      img: 'patagoniaslider-plan-2.png',
      location: 'Patagonia',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'map',
          name: 'Visita al Cerro Catedral - Día completo de esquí, con clases guiadas por instructores expertos.',
        },
        {
          icon: 'restaurant',
          name: 'Almuerzo con vista a la montaña.',
        },
      ]
    },
    {
      id: 8,
      title: 'Día 3',
      desc: 'Paseo en Cerro Otto',
      img: 'patagoniaslider-plan-3.png',
      location: 'Patagonia',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'mountain',
          name: 'Visita al teleférico, caminos y senderos de Cerro Otto.',
        },
        {
          icon: 'restaurant',
          name: 'Almuerzo en el Refugio Arelauquen con vista a toda la montaña.',
        },
      ]
    },
    {
      id: 9,
      title: 'Día 4',
      desc: 'Ruta de los 7 lagos',
      img: 'patagoniaslider-plan-4.png',
      location: 'Patagonia',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'mountain',
          name: 'Recorrido de los 7 lagos con paradas en cada uno.',
        },
        {
          icon: 'restaurant',
          name: 'Almuerzo en Villa La Angostura con vista al lago.',
        },
        {
          icon: 'map',
          name: 'Recorrido de los 7 lagos a San Martín de los Andes.',
        },
      ]
    },
    {
      id: 10,
      title: 'Día 5',
      desc: 'Vuelo de regreso',
      img: 'patagoniaslider-plan-5.png',
      location: 'Patagonia',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'airplane',
          name: 'Salida de Bariloche a Buenos Aires.',
        },
      ]
    },
    {
      id: 11,
      title: 'Día 1',
      desc: 'Llegada a Ciudad  de México',
      img: 'mexico-slider-plan-1.png',
      location: 'México',
      benefits: [
        {
          icon: 'car',
          name: 'Transporte privado hasta el hotel. ',
        },
        {
          icon: 'confetti',
          name: 'Bienvenida y recepción.',
        },
        {
          icon: 'map',
          name: 'Paseo por Pit Lane. Tour guiado por la pista. Gira del trofeo del campeonato.',
        },
      ]
    },
    {
      id: 12,
      title: 'Día 2',
      desc: 'Clasificación',
      img: 'mexico-slider-plan-2.png',
      location: 'México',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'car',
          name: 'Transporte privado hasta el Autódromo de los Hermanos Rodríguez.',
        },
        {
          icon: 'car-sport',
          name: 'Experiencia F1 - Día de calificación.',
        },
      ]
    },
    {
      id: 13,
      title: 'Día 3',
      desc: '¡Llegó el día de la carrera!',
      img: 'mexico-slider-plan-3.png',
      location: 'México',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'car',
          name: 'Transporte privado hasta el Autódromo de los Hermanos Rodríguez.',
        },
        {
          icon: 'car-sport',
          name: 'Experiencia F1 – Carrera.',
        },
      ]
    },
    {
      id: 14,
      title: 'Día 4',
      desc: 'Vuelo de regreso',
      img: 'mexico-slider-plan-4.png',
      location: 'México',
      benefits: [
        {
          icon: 'chef-hat',
          name: 'Desayuno en el hotel.',
        },
        {
          icon: 'car',
          name: 'Transporte privado hasta el aeropuerto.',
        },
        {
          icon: 'airplane',
          name: 'Salida desde Ciudad de México.',
        },
      ]
    }
  ]

  useEffect(() => {
    if (sectionRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          setIsSliderVisible(entry.isIntersecting);
          console.log('isLastSlide', isLastSlide);
        },
        { threshold: 0.5 }
      );
  
      observer.observe(sectionRef.current);
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    const handleWheel = (e) => {
      if (!sliderRef.current || !isSliderVisible) { 
        sectionRef.current.classList.remove('visible')
        return
      }

     
      if (isFirstSlide && e.deltaY < 0 || isLastSlide && e.deltaY > 0) {
        // sectionRef.current.classList.remove('visible')
        return
      }
      
      e.preventDefault()
      sectionRef.current.classList.add('visible')
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

      if (e.deltaY > 0) {
        sliderRef.current.slickNext()
      } else {
        sliderRef.current.slickPrev()
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isFirstSlide, isLastSlide, isSliderVisible]);

  return (
    <section ref={sectionRef} className='SliderPlanSection'>
      <article className='title'>
        <h3 className='line-title line-title-center-lg'>¿Cuál es el plan?</h3>
      </article>

      <article className='main-slider'>
        <div className="slider-container">
          <Slider ref={sliderRef} {...settings}>
            {list.filter(itm => itm.location === tabIndex).map((item, i) => (
              <div className='card-planSlider' key={i}>
                <div className="banner">
                  <img src={`/assets/mayor-award/${item.img}`} alt={item.title} />
                </div>
                <div className="card-content-plan-slider">
                  <h4>{item.title}</h4>
                  <h5>{item.desc}</h5>
                  <ul>
                    {item.benefits.map((benefit, i) => (
                      <li key={i}>
                        <span className={`icon-slider-plan`}><Icon name={benefit.icon} /></span>
                        <p>{benefit.name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          

          </Slider>
        </div>
      </article>
    </section>
  );
}

export default SliderPlanSection;