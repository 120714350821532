import React from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'

const SectionFaqrsMayorAward = () => {
  return (
    <section className='SectionFaqrsMayorAward'>
      <article>
      <Container>
        <Columns desktop={2} mobile={1}>
        <Col top>
        <div className="faq-content">
          <h3 className='line-title line-title-center-lg'>Preguntas <br /> frecuentes</h3>
          <p className='faq-description'>Dígale adiós a las dudas y hola a su próxima aventura.</p>
        </div>
        </Col>
        <Col>
          <div className="group-items">
            <div className="item">
              <h4>¿Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto?</h4>
              <p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años</p>
            </div>
            <div className="item">
              <h4>¿Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto?</h4>
              <p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años</p>
            </div>
            <div className="item">
              <h4>¿Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto?</h4>
              <p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años</p>
            </div>
            <div className="item">
              <h4>¿Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto?</h4>
              <p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años</p>
            </div>
            <div className="item">
              <h4>¿Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto?</h4>
              <p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años</p>
            </div>
          </div>
        </Col>
        </Columns>
      </Container>
      </article>
    </section>
  )
}

export default SectionFaqrsMayorAward
