import React from 'react'
import Button from '../atoms/Button'

function Toast({
  text,
  cta,
  link,
  show,
  isTopBar
}) {

  return (
    <div className={`mc-toast-container ${isTopBar ? 'isTopBar' : 'isToast'}`}>
      <span>{text}</span>
      <Button
        type="primary"
        text={cta}
        loading={false}
        status="active"
        link={link}
      />
      <div onClick={() => show(false)} className='close-butt'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.93428 14.7563C11.638 14.7563 14.6684 11.7259 14.6684 8.02217C14.6684 4.31842 11.638 1.28809 7.93428 1.28809C4.23053 1.28809 1.2002 4.31842 1.2002 8.02217C1.2002 11.7259 4.23053 14.7563 7.93428 14.7563Z" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6.02832 9.92816L9.83983 6.1167" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.83983 9.92816L6.02832 6.1167" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
    </div>
  )
}

export default Toast