import React, { useEffect, useState, useRef, useContext } from 'react'
import Slider from "react-slick"
import Container from '../../layouts/Container/Container'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter';
import Button from '../atoms/Button';
import { StateController } from '../../App';
import Icon from '../atoms/Icon';

function StairSlider() {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [counter, setCounter] = useState(0);
  const {context} = useContext(StateController);
  const rule = 'nocomplete'

  const list =  [
    {
      id: 3,
      image: "card_demo_3.png",
      title: "París, Francia",
      desc: "Viva la magia de París durante 5 días y 4 noches con su persona favorita."
    },
    {
      id: 4,
      image: "card_demo_4.png",
      title: "Su agenda en Francia",
      desc: "Un día en el Museo Louvre y al siguiente en un partido del PSG, suena como el viaje perfecto. ¡Gane y hágalo realidad!"
    },
    {
      id: 1,
      image: "card_demo_1.png",
      title: "Patagonia, Argentina",
      desc: "Glaciares, montañas y lagos lo esperan en este paraíso al fin del mundo. ¿Se anima a descubrirlos?"
    },
    {
      id: 2,
      image: "card_demo_2.png",
      title: "Almuerzo con vista a la montaña",
      desc: "El día 2 disfrutará de una deliciosa comida frente a este increíble paisaje. imagen de las montañas"
    },
    {
      id: 5,
      image: "card_demo_5.png",
      title: "Ciudad de México",
      desc: "Prepárese para vivir la emoción del Gran Premio de México y todo lo que esta gran experiencia incluye."
    },
    {
      id: 6,
      image: "card_demo_6.png",
      title: "Velocidad y adrenalina",
      desc: "El Autódromo Hermanos Rodríguez será en punto de encuentro de la élite del automovilismo."
    }
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    centerPadding: '20px',
    swipe: false,
    draggable: false,
    beforeChange: (current, next) => {      
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true
        }
      }
    ]
  }

  useEffect(() => {
    console.log("Active Slide:", activeSlide);
  }, [activeSlide])

  useEffect(() => {
    if (context && context.user) {
      const block = context ? context.block : 1
      const currentGoal = context ? context.user[`goal_amount_${block}`] : 0
      const currentTracing = context.tracing ? context.tracing[`amount_${block}`] : 0

      if (currentGoal) {
        if (currentTracing === currentGoal) {
          setCounter(1)
        } else if (currentTracing > currentGoal) {

          setCounter(
            rule === 'complete' ? Math.round(currentTracing / currentGoal) : (currentTracing / currentGoal).toFixed(1)
          )
        }
      }
    }
  }, [context])
  console.log("list", list)

  return (
    <div className='mc-stair-slider'>
      
      <div className={`mc-stair-slider__carousel active-item-${activeSlide}`}>

        <Container>
          <div className='mc-stair-slider__carousel--labels'>
            <VerticalCenter>
              <h3>¿Listo para conocer su próximo destino?</h3>
              <div className="mc-page-content__separator no-desktop">
                <img src="/assets/images/separator.png" alt="Titulos" />
              </div>
              <p>Use su Tarjeta de Crédito Davivienda Mastercard para todo y participe por este increíble viaje.</p>
              <p className='no-mobile'>Cuantas más veces cumpla su meta de facturación, estará más cerca de redimir el premio mayor.</p>
              <div className='mc-stair-slider__carousel--labels__tooltip no-mobile'>
                <Icon name="check" /> Ha cumplido su meta <strong>{counter}</strong> veces
              </div>
              <div className='mc-stair-slider__carousel--labels__cta no-mobile'>
                <Button
                  type='primary'
                  icon='check'
                  text={'Quiero el premio mayor'}
                  status="active"
                  link={'/premio-mayor'}
                />
              </div>
            </VerticalCenter>
          </div>

          <Slider ref={sliderRef} {...settings}>
            {list.map((slide, index) => {

              return (
                <div key={index} className='mc-stair-slider__carousel--item'>
                  <div 
                    className='mc-stair-slider__carousel--item__container' 
                    style={{"backgroundImage": `url(/assets/images/${slide.image})`}}
                  >
                      <div className='overlay-card'>
                        <VerticalCenter>
                          <h4>{slide.title}</h4>
                          <p>{slide.desc}</p>
                        </VerticalCenter>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M54.1667 55.0005V15.9186L60 10.0005L60 55.0005L60 60.8338H54.1667L10 60.8338L15.0847 55.0005H54.1667Z" fill="#ED1C27"/>
                        <g filter="url(#filter0_f_4488_28960)">
                          <mask id="path-2-outside-1_4488_28960" maskUnits="userSpaceOnUse" x="8" y="8.00049" width="54" height="55" fill="black">
                            <rect fill="white" x="8" y="8.00049" width="54" height="55"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M54.1667 55.0005V15.9186L60 10.0005L60 55.0005L60 60.8338H54.1667L10 60.8338L15.0847 55.0005H54.1667Z"/>
                          </mask>
                          <path d="M54.1667 15.9186L52.9797 14.7486C52.6723 15.0604 52.5 15.4807 52.5 15.9186H54.1667ZM54.1667 55.0005V56.6672C55.0871 56.6672 55.8333 55.921 55.8333 55.0005H54.1667ZM60 10.0005L61.6667 10.0005C61.6667 9.3242 61.258 8.71495 60.6322 8.4584C60.0065 8.20185 59.2878 8.34886 58.813 8.83051L60 10.0005ZM60 55.0005H61.6667H60ZM60 60.8338V62.5005C60.9205 62.5005 61.6667 61.7543 61.6667 60.8338H60ZM54.1667 60.8338V62.5005V60.8338ZM10 60.8338L8.74364 59.7387C8.3143 60.2312 8.21238 60.9292 8.48293 61.5239C8.75348 62.1187 9.34661 62.5005 10 62.5005L10 60.8338ZM15.0847 55.0005V53.3338C14.603 53.3338 14.1449 53.5422 13.8284 53.9053L15.0847 55.0005ZM52.5 15.9186V55.0005H55.8333V15.9186H52.5ZM58.813 8.83051L52.9797 14.7486L55.3536 17.0885L61.187 11.1705L58.813 8.83051ZM61.6667 55.0005L61.6667 10.0005L58.3333 10.0005L58.3333 55.0005H61.6667ZM58.3333 55.0005L58.3333 60.8338H61.6667L61.6667 55.0005H58.3333ZM60 59.1672H54.1667V62.5005H60V59.1672ZM54.1667 59.1672L10 59.1671L10 62.5005L54.1667 62.5005V59.1672ZM11.2564 61.929L16.3411 56.0956L13.8284 53.9053L8.74364 59.7387L11.2564 61.929ZM15.0847 56.6672H54.1667V53.3338H15.0847V56.6672Z" fill="#FFCD00" mask="url(#path-2-outside-1_4488_28960)"/>
                        </g>
                        <g>
                          <path fillRule="evenodd" clipRule="evenodd" d="M54.1667 55.0005V15.9186L60 10.0005L60 55.0005L60 60.8338H54.1667L10 60.8338L15.0847 55.0005H54.1667Z" fill="#ED1C27"/>
                        </g>
                        <defs>
                          <filter id="filter0_f_4488_28960" x="0.500163" y="0.500651" width="68.9997" height="69.8332" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="3.91667" result="effect1_foregroundBlur_4488_28960"/>
                          </filter>
                        </defs>
                      </svg>
                  </div>
                </div>
              )
            })}
          </Slider>

          <div className='desktop-styles'>
            <p className='no-desktop'>Cuantas más veces cumpla su meta de facturación, estará más cerca de redimir el premio mayor.</p>
            <div className='mc-stair-slider__carousel--labels__tooltip no-desktop'>
              <Icon name="check" /> Ha cumplido su meta <strong>{counter}</strong> veces
            </div>
            <div className='mc-stair-slider__carousel--labels__cta no-desktop'>
              <Button
                type='primary'
                icon='check'
                text={'Quiero el premio mayor'}
                link={'/premio-mayor'}
                status="active"
              />
            </div>
          </div>
        </Container>

      </div>

    </div>
  )
}

export default StairSlider