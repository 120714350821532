import React from 'react'
import Container from '../../layouts/Container/Container'
import { Link } from 'react-router-dom'

const SectionTYC = () => {
  return (
    <section className='SectionTYC'>
      <article>
        <Container>
          <div className="wrap-tyc">
            <div className="icon-tyc"><img src="/assets/images/icon-tyc.png" alt="" /></div>
            <p className='description-tyc'>¿Quieres conocer más detalles? <br />
              Consulta los <Link to='terminos-y-condiciones'>términos y condiciones.</Link></p>
          </div>
        </Container>
      </article>
    </section>
  )
}

export default SectionTYC
