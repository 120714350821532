import React from 'react'
import { Link } from "react-router-dom"

import Container from '../Container/Container'
import { sendDataLayer } from '../../utils/functions'

function Footer({
  footerType,
  secondaryFooterImg,
  links,
  linksText,
  content,
  bankFooterImg
}) {

  const addDatalayerFooter = (e) => {
    if (e.target.closest('a')) {
      let text = e.target.closest('a').innerText
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({datalayer: {event: 'footer',text: text, link: link},repeat: true})
    }
  }
  

  return (
    <div className={`mc-footer-container mc-footer-container-type-${footerType}`}>
      <img src={`/assets/images/new-footer-image.png`} alt="Footer Cards" className='mc-footer-container__new' />

      {footerType === 1 && 
        <div className='mc-footer-container__bg'>
          <Container>
            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      link.external
                        ? <a key={index} href={link.link}>{link.text}</a>
                        : <Link 
                          key={index}
                          to={link.link} 
                          onClick={addDatalayerFooter}
                        >{link.text}</Link>
                    )
                  })}
                </ul>
              }

              {linksText && 
                <div dangerouslySetInnerHTML={{ __html: linksText }} />
              }
              {secondaryFooterImg &&
                <img 
                  src={`/assets/images/${secondaryFooterImg}`} 
                  alt="Footer" 
                  className='mc-footer-container__image'
                />
              }
            </div>
          </Container>
            <Container className="mc-footer-container__text">
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <img
                src="/assets/images/vigilated.png"
                className="mc-footer-vigilated" alt="Footer logos"
              />
            </Container>
        </div>
      }

      {footerType === 1 &&
        <div className='mc-footer-container__subbg'>
          <Container>
            <img src={`/assets/images/${bankFooterImg}`} alt="Banco Footer" />
          </Container>
        </div>
      }

    </div>
  )
}

export default Footer