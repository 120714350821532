import React, { useState, useEffect, useContext, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageSlider from '../molecules/ImageSlider';
import Container from "../../layouts/Container/Container";
import SectionCards from '../molecules/SectionCards';
import { StateController } from '../../App';
import SliderPlanSection from '../organisms/SliderPlanSection';
import SectionFaqrsMayorAward from '../organisms/SectionFaqrsMayorAward';
import Icon from '../atoms/Icon';
import useWidth from '../../hooks/useWidth';
import SectionTYC from '../molecules/SectionTYC';

const MajorAward = () => {
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [tabIndex, setTabIndex] = useState('París');
  const [animated, setAnimated] = useState(false);
  const {context} = useContext(StateController)
  const { width } = useWidth()
  
  const slides = [
    {
      id: 1,
      title: 'París - Francia',
      description1: '¿París es su destino? ¡Conviértase en el ganador!',
      description2: 'Visite la Torre Eiffel, cene en el Moulin Rouge, viva un partido de fútbol del PSG y mucho más.',
      cardImage: 'card-banner-premio-mayor-francia.png',
      backgroundImage: 'banner-premio-mayor-francia.png',
      backgroundImageMobile: 'banner-premio-mayor-francia-mobile.png',
    },
    {
      id: 2,
      title: 'Patagonia - Argentina',
      description1: 'Gane y atrévase a explorar la Patagonia',
      description2: 'Si lo suyo es la aventura y caminar entre glaciares, este es el destino perfecto para usted.',
      cardImage: 'card-banner-premio-mayor-argentina.png',
      backgroundImage: 'banner-premio-mayor-argentina.png',
      backgroundImageMobile: 'banner-premio-mayor-argentina-mobile.png',
    },
    {
      id: 3,
      title: 'Ciudad de México, MX',
      description2: 'México, para amantes de la velocidad',
      description1: '¿Se imagina caminando por el Pit Lane, conociendo los equipos de F1 y los autos más rápidos del mundo?',
      cardImage: 'card-banner-premio-mayor-mexico.png',
      backgroundImage: 'banner-premio-mayor-mexico.png',
      backgroundImageMobile: 'banner-premio-mayor-mexico-mobile.png',

    },

    {
      id: 4,
      title: 'París - Francia',
      description1: '¿París es su destino? ¡Conviértase en el ganador!',
      description2: 'Visite la Torre Eiffel, cene en el Moulin Rouge, viva un partido de fútbol del PSG y mucho más.',
      cardImage: 'card-banner-premio-mayor-francia.png',
      backgroundImage: 'banner-premio-mayor-francia.png',
      backgroundImageMobile: 'banner-premio-mayor-francia-mobile.png',
    },
    {
      id: 5,
      title: 'Patagonia - Argentina',
      description1: 'Gane y atrévase a explorar la Patagonia',
      description2: 'Si lo suyo es la aventura y caminar entre glaciares, este es el destino perfecto para usted.',
      cardImage: 'card-banner-premio-mayor-argentina.png',
      backgroundImage: 'banner-premio-mayor-argentina.png',
      backgroundImageMobile: 'banner-premio-mayor-argentina-mobile.png',
    },
    {
      id: 6,
      title: 'Ciudad de México, MX',
      description2: 'México, para amantes de la velocidad',
      description1: '¿Se imagina caminando por el Pit Lane, conociendo los equipos de F1 y los autos más rápidos del mundo?',
      cardImage: 'card-banner-premio-mayor-mexico.png',
      backgroundImage: 'banner-premio-mayor-mexico.png',
      backgroundImageMobile: 'banner-premio-mayor-mexico-mobile.png',

    },
  ];

  const sliderSettings = {
    dots: false,
    arrows:false,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 1,
    centerMode: false,
    centerPadding: "0px 0px",
    focusOnSelect: true,
    // variableWidth: true,
    beforeChange: (current, next) => {
      setActiveIndex(next)
      triggerButtonAnimation()
    },
    afterChange: (current) => setActiveIndex(current),
    accessibility: true,
    autoplay: true,
    
  };

  const togglePause = () => {
    if (isPaused) {
      sliderRef.current.slickPlay()
    } else {
      sliderRef.current.slickPause()
    }
    setIsPaused(!isPaused);
  };

  const progressPercentage = ((activeIndex + 1) / slides.length) * 100;

  useEffect(() => {
    const progressBar = document.querySelector('.progress-bar');
    if (progressBar) {
      progressBar.style.width = `${progressPercentage}%`;
    }
  }, [activeIndex, progressPercentage]);

  const handleTabClick = (tab) => {
    setTabIndex(tab);
  }

  const triggerButtonAnimation = () => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 500)
  };

  const tabs = [
    { label: 'París', image: '/assets/mayor-award/fr.png', key: 'París' },
    { label: 'Patagonia', image: '/assets/mayor-award/ar.png', key: 'Patagonia' },
    { label: 'México', image: '/assets/mayor-award/mx.png', key: 'México' },
  ]

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page major-award mc-page-major-award slow_ani'>
      <div className='mc-page-content'>

        <div
          className='slider-bg'
          style={{
            backgroundImage: `url(/assets/mayor-award/${width > 992 ? slides[activeIndex].backgroundImage : slides[activeIndex].backgroundImageMobile })`,
          }}
        >
          <div className='section-slider-mayor-award'>
            <div className={`slider-content ${animated ? "animated" : ""} `}>
              <p>{slides[activeIndex].title}</p>
              <h3 className='line-title'>{slides[activeIndex].description1}</h3>
              <h5>{slides[activeIndex].description2}</h5>
            </div>

            <div className='slider-cards'>
              <Slider {...sliderSettings} ref={sliderRef}>
                {slides.map((slide, index) => (
                  <div
                    className='slider-image'
                    key={slide.id}
                    style={{
                      padding: '10px',
                      transform: index === 0 ? 'scale(1.2)' : 'scale(0.9)',
                      opacity: index === 0 ? 1 : 0.7,
                      transition: 'transform 0.5s ease, opacity 0.5s ease',
                    }}
                  >
                    <img
                      src={`/assets/mayor-award/${slide.cardImage}`}
                      alt={`Card ${index + 1}`}
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '10px',
                        boxShadow: activeIndex === index ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            
          </div>

          <button 
            className="pause-button" 
            onClick={togglePause}>{isPaused ? <Icon name='play' /> : <Icon name='pause' />}
          </button>
          <div className='wrap-progress-pause'>

          <div
            className="progress-bar"
            style={{
        
              background: '#ff0000',
              transition: 'width 0.5s ease',
            }}
          ></div>
        </div>
        </div>



        <section className="section-tab-mayor-award">
          <article>
            <Container>

              <div className="tab items">
                {tabs.map((tab) => (
                  <div
                    key={tab.key}
                    className={`tab-item ${tabIndex === tab.key ? 'active' : ''}`}
                    onClick={() => handleTabClick(tab.key)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') handleTabClick(tab.key);
                    }}
                    aria-selected={tabIndex === tab.key}
                  >
                    <h3>
                      <img src={tab.image} alt={`${tab.label} icon`} /> {tab.label}
                    </h3>
                  </div>
                ))}
              </div>
            </Container>
          </article>
        </section>

        <section className='section-ImageSlider'>
          <article className="content-imageSlider">
            <Container>

              <div>
                <h3 className='line-title line-title-center-lg'>¿Qué incluye el viaje?</h3>
                <p>Conozca todos los detalles de su viaje a París y prepárese para alcanzar el premio mayor.</p>

              </div>
            </Container>
          </article>

          <article className="ImageSlider">
            <ImageSlider tabIndex={tabIndex} />

          </article>
        </section>

        <section>
          <article>
          <SectionCards
            tabIndex={tabIndex}
            title="Déjenos todo a nosotros"
            subtitle="Es hora de que disfrute la recompensa por su esfuerzo en Compras Maestras. "
            subtitle2="Encuentre más información del viaje aquí."
            context={context}
            items={
              [
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-2.png',
                  cardtitle: 'Seguro de viaje',
                  cardDescription: 'Viaje tranquilo durante toda su aventura.',
                  location:'París'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-1.png',
                  cardtitle: 'Tiquetes',
                  cardDescription: 'Los vuelos de ida y regreso están incluidos.',
                  location:'París'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-4.png',
                  cardtitle: 'Kit de bienvenida',
                  cardDescription: 'Un regalo pensado para empezar con el pie derecho.',
                  location:'París'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-3.png',
                  cardtitle: 'No necesita visa',
                  cardDescription: 'Disfrute sin preocuparse por trámites adicionales.',
                  location:'París'
                },
              
              
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-5.png',
                  cardtitle: 'Anfitrión',
                  cardDescription: 'Tendrá la compañía de un experto que lo guiará durante el viaje.',
                  location:'París'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-1.png',
                  cardtitle: 'Tiquetes',
                  cardDescription: 'Los vuelos de ida y regreso están incluidos.',
                  location:'Patagonia'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-2.png',
                  cardtitle: 'Seguro de viaje',
                  cardDescription: 'Viaje tranquilo durante toda su aventura.',
                  location:'Patagonia'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-3.png',
                  cardtitle: 'No necesita visa',
                  cardDescription: 'Disfrute sin preocuparse por trámites adicionales.',
                  location:'Patagonia'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-4.png',
                  cardtitle: 'Kit de bienvenida',
                  cardDescription: 'Un regalo pensado para empezar con el pie derecho.',
                  location:'Patagonia'
                },
            
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-5.png',
                  cardtitle: 'Anfitrión',
                  cardDescription: 'Tendrá la compañía de un experto que lo guiará durante el viaje.',
                  location:'Patagonia'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-1.png',
                  cardtitle: 'Tiquetes',
                  cardDescription: 'Los vuelos de ida y regreso están incluidos.',
                  location:'México'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-2.png',
                  cardtitle: 'Seguro de viaje',
                  cardDescription: 'Viaje tranquilo durante toda su aventura.',
                  location:'México'
                },
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-3.png',
                  cardtitle: 'No necesita visa',
                  cardDescription: 'Disfrute sin preocuparse por trámites adicionales.',
                  location:'México'
                },
                {
                  cardImg: 'mexico-destinos-card-premio-premio-mayor-cascada-4.png',
                  cardtitle: 'Regalo F1 McLaren',
                  cardDescription: 'Recibirá un detalle exclusivo con productos de la marca McLaren.',
                  location:'México'
                },
            
                {
                  cardImg: 'paris-destinos-card-premio-premio-mayor-cascada-5.png',
                  cardtitle: 'Anfitrión',
                  cardDescription: 'Tendrá la compañía de un experto que lo guiará durante el viaje.',
                  location:'México'
                },

              ]
            }
          />
          </article>
        </section>

        <SliderPlanSection tabIndex={tabIndex}/>

        <SectionFaqrsMayorAward />

        <SectionTYC />
      </div>

    </div>
  );
};

export default MajorAward;
